import { Link, graphql } from "gatsby"
import React from 'react'
import MainLayout from '../layouts/MainLayout'
import SEO from "../components/SEO"
import FirstLevelBanner from '../components/our-work/first-level/Banner'
import Banner from '../components/our-work/second-level/Banner'
import ContributionTrain from "../components/our-work/second-level/ContributionTrain"
import DonationBox from "../components/our-work/second-level/DonationBox"

import Content from "../components/our-work/second-level/Content"
import Media from "../components/our-work/second-level/Media"

export default function DonationAnnouncementTemplate({ data }) {
    return (
        <MainLayout>
            <SEO title={`${data.strapiPages.Seo.title}`} description={`${data.strapiPages.Seo.description}`} />
            {data && data.strapiPages.donationStatus && data.strapiPages.donation.trainText ? <Banner sources={data.strapiPages.HeroImage} /> : <FirstLevelBanner sources={data.strapiPages.HeroImage} />}
            {data && data.strapiPages.donationStatus && data.strapiPages.donation.trainText &&
                <ContributionTrain
                    trainText={data.strapiPages.donation.trainText}
                    target={data.strapiPages.donation.target}
                    raised={data.strapiPages.donation.raised}
                    raisedOnline={data.strapiPages.donation.raisedOnline}
                    raisedOffline={data.strapiPages.donation.raisedOffline}
                    targetType={data.strapiPages.donation.targetType}
                    cost={data.strapiPages.donation.cost}
                />
            }
            <section className="container">
                <h1 className="page-title">{data.strapiPages.Header}</h1>
                <div className="work-container">
                    {data && data.strapiPages.donationStatus &&
                        <DonationBox
                            target={data.strapiPages.donation.target}
                            raised={data.strapiPages.donation.raised}
                            raisedOnline={data.strapiPages.donation.raisedOnline}
                            raisedOffline={data.strapiPages.donation.raisedOffline}
                            currentAppeal={data.strapiPages.donation.currentAppeal}
                            formID={data.strapiPages.donation.formID}
                            zakatEligible={data.strapiPages.donation.zakatEligible}
                            barometerText={data.strapiPages.donation.barometerText}
                        />
                    }
                    <Content content={data.strapiPages.Description} projectUpdates={data.strapiPages.ProjectUpdates} donationStatus={data.strapiPages.donationStatus} />
                </div>
            </section>
            <Media imageSource={data.strapiPages.Pictures} videoSource={null} />
        </MainLayout>
    )
}

export const query = graphql`
  query($slug: String!) {
    strapiPages(Slug: { eq: $slug }) {
        Header
        Description
        Pictures {
            id
            width
            height
            alternativeText
            url
            
        }
        HeroImage {
            desktopImage {
                localFile{
                    publicURL
                }
            }
            mobileImage{
                localFile{
                    publicURL
                }
            }
        }
        Seo {
            description
            title
        }
        donationStatus
        donation {
            target
            raised
            cost
            formID
            crmLookupId
            zakatEligible
            trainText
            currentAppeal
            targetType
            raisedOffline
            raisedOnline
            barometerText
        }
        ProjectUpdates {
            Content
            Date(formatString: "DD/MM/YYYY")
            Header
            ProjectName
            Region
        }
    }
  }
`